import styled from 'styled-components'
import React from 'react'

export const StyledFooter = styled(({ gateway, dark, ...props }) => <footer {...props} />)`
  background-color: ${props => props.gateway
    ? props.theme.colors.blue
    : (props.dark ? props.theme.colors.mainFooterNavyDark : props.theme.colors.mainHeaderNavyDark)};
  padding-top: 80px;
  .social-icons {
      margin: 0 auto 60px;
      width: auto;
      justify-content: center;
      display: flex;
      max-width: 320px;
      .social-circle {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-color: ${props => props.theme.colors.aquaBlue};
          border-radius: 32px;
          margin: 0px 15px;       
      }        
  }
  .left-col {
      letter-spacing: 0.5px;
      p {
          margin: 20px 0 0 0;
          color: ${props => props.theme.colors.lightGrey};
          font-size: 16px;
          line-height: 20px;
      }
  }
  .zoopay-logo {
      font-size: 35px;
      letter-spacing: 0.5px;
      color: ${props => props.theme.colors.grey};
      img {
        width: 161px;
        height: 45px;
      }
  }
  .links-column {
      ul {
          list-style-type: none;
          margin: 14px 0 2px 0;
          padding: 0;
      }
      .li-header {
          margin: 0 0 22px 0;
      }
      li {
          color: ${props => props.theme.colors.aquaTextFooter};
          font-size: 21px;
          font-weight: bold;
          a {
            font-size: 16px;
            color: ${props => props.theme.colors.lightGrey};
            margin: 0px 0 0 0;
            font-weight: normal;
            &:hover {
                text-decoration: none;
                color: ${props => props.theme.colors.aquaBlue};
            }
          }
      }
  }
  .footer-info {
      color: ${props => props.theme.colors.aquaTextFooter};
      font-size:12px;
      line-height: 16px;
      letter-spacing: 0.15px;
      text-align: center;
      max-width: 590px;
      margin: 130px auto 0;
  }
  .footer-copyrights {
      margin: 30px auto 70px;
      color: ${props => props.theme.colors.aquaTextFooter};
      font-size:12px;
      letter-spacing: 0.15px;
      text-align: center;
      font-weight: bold;
  }

  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 50px 15px 0;
      background-color: background-color: ${props => props.gateway
        ? props.theme.colors.blue
        : props.theme.colors.mainHeaderNavyDark};
      .social-icons {
          display: flex;
          justify-content: space-between;
          width: auto;
          margin: 0 auto 33px;
          .social-circle {
              display: inline-block;
              width: 32px;
              height: 32px;
              background-color: transparent;
              border-radius: 32px;
              margin: 0 auto;
              flex: 0 0 auto;
          }
          &.second {
              justify-content: center;
          }
      }
      .zoopay-logo {
          p{
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.15px;
              margin: 11px 0 0 0px;
          }
      }
      .links-column {
          ul{
              margin: 30px 0 2px 0;
              li a {
                  font-size: 14px;
                  line-height: 30px;
              }
          }
          .li-header {
              font-size: 12px;
              line-height: 23px;
              letter-spacing: 1px;
              color: ${props => props.theme.colors.aquaFooter};
              text-transform: uppercase;
              font-family: 'Comfortaa', cursive;
              margin: 0 0 3px 0;
          }
      }
      .footer-info {
          margin: 30px auto 0;
      }
      .footer-copyrights {
          margin: 28px auto 50px;
      }
  }
`

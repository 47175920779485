import React from 'react'
import styled, { css } from 'styled-components'

const Inner = styled.div`
  padding: 3.25rem 0;
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.greyDark};
    `}

  ${props =>
    props.teal &&
    css`withComponent
      form{
        background: ${props => props.theme.colors.tealBackground};
        label {
          color: ${props => props.theme.colors.white};
        }
        input {
          background: red;
        }
      }
    `}

  ${props =>
    props.paddingBottom &&
    css`
      padding-bottom: 0px !important;
    `}

  ${props => 
    props.lowLineHeight && 
    css`
      p {
        line-height: ${props => props.theme.type.lineHeights.small};
      }
    `}

  ${props => 
    props.noSectionPadding && 
    css`
      margin: 0 -15px;
    `}
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    ${props => 
    props.noSectionPadding && 
    css`
      margin: 0 -20px;
    `}
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    padding: 5.25rem 0;
  }
`

const SectionInner = ({ borderBottom, paddingBottom, children, teal, lowLineHeight, noSectionPadding }) => <Inner borderBottom={borderBottom} teal={teal} paddingBottom={paddingBottom} lowLineHeight={lowLineHeight} noSectionPadding={noSectionPadding}>{children}</Inner>

export default SectionInner

import React from 'react'
import Helmet from 'react-helmet'

import config from '../../config'

const SEOPageTitle = ({ metaTitle, title }) => {
  let result

  if (metaTitle) {
    result = metaTitle
  } else {
    result = `${title} - ${config.siteTitle}`
  }

  return (
    <Helmet>
      <title>{result}</title>
    </Helmet>
  )
}

export default SEOPageTitle

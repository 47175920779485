import styled, { css } from 'styled-components'
import React from 'react'

const StyledButton = styled(({ fullWidth, ...props }) => <button {...props} />)`
  appearance: none;
  background: none;
  border-radius: 0;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.type.fonts.base};
  margin: 0;
  outline: none;
  resize: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: ${props => props.theme.type.weights.bold};
  transition: ${props => props.theme.animations.transition};
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  &:focus {
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    display: block;
    width: 100%;
  }

  ${props => 
    props.addTopPadding &&
    css`
      margin-top:30px;
    `}

  ${props => 
    props.addBottomPadding &&
    css`
      margin-bottom:50px;
    `}



  ${props =>
    props.size === 'small' &&
    css`
      font-size: ${props => props.theme.type.sizes.tiny};
      padding: 1rem 1.75rem;
    `}

  ${props =>
    props.size === 'medium' &&
    css`
      font-size: ${props => props.theme.type.sizes.small};
      padding: 1.25rem 2.75rem;
    `}

  ${props =>
    props.size === 'large' &&
    css`
      font-size: ${props => props.theme.type.sizes.base};
      min-width: 275px;
      padding: 1.5rem 2.75rem;

      @media (min-width: ${props => props.theme.responsive.medium}) {
        padding: 1.5rem 3.75rem;
      }
    `}

  ${props =>
    props.level === 'primary' &&
    css`
      background-color: ${props => props.theme.colors.green};

      :hover {
        background-color: ${props => props.theme.colors.greenHover};
      }
    `}

  ${props =>
    props.level === 'secondary' &&
    css`
      background-color: ${props => props.theme.colors.aquaBlue};
      color: ${props => props.theme.colors.mainHeaderNavyDark};
      
      :hover {
        background-color: ${props => props.theme.colors.aquaBtnHover};
      }
    `}

  ${props =>
    props.level === 'error' &&
    css`
      background-color: ${props => props.theme.colors.red};

      :hover {
        background-color: ${props => props.theme.colors.redHover};
      }
    `}

  ${props =>
    props.level === 'warning' &&
    css`
      background-color: ${props => props.theme.colors.orange};

      :hover {
        background-color: ${props => props.theme.colors.orangeHover};
      }
    `}

    ${props =>
      props.level === 'basic' &&
      css`
        color: ${props => props.theme.colors.black};
        background-color: ${props => props.theme.colors.white};

        :hover {
          background-color: ${props => props.theme.colors.whiteHover};
        }
      `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props => props.theme.colors.grey};

      :hover {
        background-color: ${props => props.theme.colors.grey};
      }
    `}
`
export { StyledButton }

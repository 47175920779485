import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
  StyledHeader,
  AnimatedButton,
} from './styles'
import zoopayLogoHeader from '../../images/zoopayLogo.svg'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  componentDidMount() {
    this.setState({ isOpen: false })
  }

  onClickHandler = () => {
    this.setState({ isOpen: !this.state.isOpen })
    //
    // if (this.state.isOpen) {
    //   window.Intercom('update', { hide_default_launcher: false })
    //   document.body.classList.remove('menu-open')
    // } else {
    //   window.Intercom('update', { hide_default_launcher: true })
    //   document.body.classList.add('menu-open')
    // }
  }

  render() {
    const { isOpen } = this.state
    const { gateway, dark } = this.props

    return (
      <StyledHeader isOpen={isOpen} gateway={gateway} dark={dark}>
        <div className="header-wrapper">
          <div className="header-container container">
            <nav className="navbar navbar-expand-sm">
              <AnimatedButton className="navbar-toggler third-button d-block d-sm-none" type="button"
                      onClick={this.onClickHandler} isOpen={isOpen} gateway={gateway}>
                <div className="animated-icon"><span></span><span></span><span></span></div>
              </AnimatedButton>
              <Link className="navbar-brand header-logo" to="/">
                <img src={zoopayLogoHeader} alt="Zoopay" />
              </Link>
              <div className={`collapse navbar-collapse${isOpen ? ' show' : ''}`}>
                <ul className="navbar-nav mr-auto mt-lg-0">
                  <div className="container-left">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/gateway">Gateway</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">About Us</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">Contact</Link>
                    </li>
                  </div>
                  <div className="container-right nav-item">
                    <li className="nav-item item log-in">
                      <a className="nav-link btn btn-secondary" href="https://arch-login.zoopay.com/#/login">Arch Login</a>
                    </li>
                    <li className="nav-item item try-demo">
                      <Link className="nav-link btn btn-primary aqua" to={gateway ? '/contact' : '/demo'}>Try demo</Link>
                    </li>
                  </div>
                </ul>
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                </div>
              </div>
            </nav>
          </div>
        </div>
      </StyledHeader>
    )
  }
}

export default Menu

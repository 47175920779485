import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { getCookie, setCookie } from '../../utils/cookies'

import Button from '../Button'
import Container from '../Container'

const Orange = styled.div`
  background-color: ${props => props.theme.colors.orange};
`

const Policy = styled(Container)`
  position: relative;
  top: 0;
  z-index: 10;
`

const Inner = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 1rem 0;

  @media (min-width: ${props => props.theme.responsive.large}) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`

const CustomLink = styled(Link)`
  color: ${props => props.theme.colors.white};
`

const PBold = styled.p`
  font-size: ${props => props.theme.type.sizes.tiny};
  font-weight: ${props => props.theme.type.weights.bold};
  margin: 0;
  padding: 0;
  text-align: left;

  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.small};
  }
`

const CustomButton = styled(Button)`
  background-color: ${props => props.theme.colors.orange};
  border: 1px solid ${props => props.theme.colors.navy};
  color: ${props => props.theme.colors.navy};
  font-size: ${props => props.theme.type.sizes.tiny};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: auto;
  margin-top: 0.75rem;

  :hover {
    background-color: ${props => props.theme.colors.orangeHover};
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    margin: 0;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
`

class CookiePolicy extends Component {
  constructor(props) {
    super(props)
    this.state = { status: true }
  }

  componentDidMount() {
    const status = getCookie('cookie-policy') === 'true'
    this.setState({ status: status })
  }

  onClickHandler = () => {
    setCookie('cookie-policy', 'true', 365)
    this.setState({ status: true })
  }

  render() {
    const { status } = this.state

    return (
      <>
        {!status && (
          <Orange className="cookie-policy">
            <Policy>
              <Inner>
                <PBold>
                  We use cookies to provide you with a better service.
                  <br />
                  Continue browsing if you’re happy with this and accept our{' '}
                  <CustomLink to="/cookies-policy">Cookie Policy</CustomLink>
                </PBold>
                <CustomButton onClick={this.onClickHandler}>Understood</CustomButton>
              </Inner>
            </Policy>
          </Orange>
        )}
      </>
    )
  }
}

export default CookiePolicy

import React from 'react'
import { Link } from 'gatsby'

import { StyledButton } from './styles'

const Button = ({
  as,
  children,
  className,
  disabled = false,
  fullWidth = false,
  href,
  level = 'primary',
  onClick,
  size = 'medium',
  to,
  type,
  addTopPadding = false,
  addBottomPadding = false,
}) => {
  const Tag = to ? StyledButton.withComponent(Link) : StyledButton

  return (
    <Tag
      as={as}
      className={className}
      disabled={disabled}
      href={href}
      level={level}
      onClick={onClick}
      size={size}
      to={to}
      type={type}
      fullWidth={fullWidth && true}
      addTopPadding={addTopPadding}
      addBottomPadding={addBottomPadding}
    >
      {children}
    </Tag>
  )
}

export default Button

import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
  display: block;
  margin: 0 auto;
  padding: ${props => (props.enableMobileGutter ? '0 1.5rem' : 0)};
  width: 100%;

  @media (min-width: ${props => props.theme.responsive.small}) {
    max-width: 540px;
    padding: 0 1rem;
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 720px;
    padding: 0 1rem;
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    max-width: 960px;
    padding: 0 1rem;
  }

  @media (min-width: ${props => props.theme.responsive.huge}) {
    max-width: ${props => props.theme.responsive.limit};
    padding: 0 1rem;
  }
`

export { StyledContainer }

import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  margin-top: 0;
  padding: 0;
  font-size: ${props => props.theme.type.sizes.hardSizes.massive};
  font-weight: 700;
  
  span {
    color: ${props => props.theme.colors.aquaBlue};
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.bigger};
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.large};
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.massive};
  }

  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.bigger};
  }
`

const SlimTitle = ({ children }) => <Title>{children}</Title>
export default SlimTitle

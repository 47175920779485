const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
const globalConfig = require('./global.js')
let envConfig = null
let localConfig = null
try {
  envConfig = require(`./env.${env}.js`)
} catch (e) {}


const mergedConfig = Object.assign(globalConfig, envConfig, localConfig)

module.exports = {
  env,
  ...mergedConfig,
}

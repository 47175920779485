const theme = {
  animations: {
    transition: 'all 0.3s ease-in-out',
    transitionQuick: 'all 0.2s ease-in-out',
  },
  colors: {
    black: '#25262C',
    green: '#10C195',
    greenHover: '#0DA882',
    greyDark: '#707070',
    greyDarkHover: '#7D7D7D',
    greyMedium: '#c0c0c0',
    greyLight: '#E5E5E5',
    greyLightHover: '#E8E8E8',
    navy: '#1C2153',
    navyHover: '#0a0f3c',
    oceanBlue: '#2D90F4',
    oceanBlueHover: '#2781DB',
    orange: '#F6A85F',
    orangeHover: '#DB9656',
    pink: '#FFD3DD',
    red: '#F6466F',
    redHover: '#DB3D62',
    skyBlue: '#00C4FF',
    skyBlueHover: '#00B0E6',
    teal: '#BDEBE0',
    whiteHover: '#FCFCFC',

    hardBlue: '#211880',
    blue : '#4130f9',
    deepBlue : '#3D34F3',
    headerDeepBlue : '#2D27AD',
    mainHeaderDeepBlue : '#231E7C',
    mainHeaderNavyDark: '#083959',
    mainFooterNavyDark: '#00334a',
    navyBtnHover: '#093f63',
    navyBtnSelected: '#0e4459',
    deepButtonBlue : '#083959',
    white : '#fff',
    lightGrey: '#e5e6e6',
    darkGrey: '#b0b0b0',
    aquaBlue: '#00f0e0',
    aquaBtnHover : '#a5fff9',
    aquaBtnSelected : '#00bdb1',
    aquaFooter : '#00f0e1',
    aquaTextFooter : '#2ebdbd',
    grey: '#dddee0',
    headerOpenGrey : '#dddee0',
    backgroundGrey : '#e4e4e4',
    navyDeepBlue : '#002231',
    tealBackground : '#003c58',
  },
  sizes: {
    maxWidth: '1280px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '576px',
    medium: '768px',
    large: '992px',
    huge: '1200px',
    limit: '1140px',
  },
  boxShadow: 'rgba(0, 0, 0, 0.5) 0 0 10px',
  type: {
    fonts: {
      base: '"Comfortaa", cursive',
    },
    shadow: '0px 0px 10px rgba(28,33,83,0.35)',
    sizes: {
      massive: '1.875rem',
      huge: '1.75rem',
      big: '1.75rem',
      large: '1.5rem',
      mediumLarge: '1.25rem',
      medium: '1.25rem',
      base: '1rem',
      small: '0.875rem',
      tiny: '0.75rem',
      tinier: '0.7rem',
      desktop: {
        massive: '3rem',
        huger: '2.88rem',
        huge: '2.25rem',
        big: '1.875rem',
        large: '1.75rem',
        mediumLarge: '1.5rem',
        medium: '1.25rem',
        base: '1rem',
        small: '0.875rem',
        tiny: '0.75rem',
      },
      hardSizes: {
        massive: '40px',
        bigger: '24px',
        big: '22px',
        basePlus: '20px',
        base: '18px',
        small: '16px',
        smaller: '16px',
      },
    },
    lineHeights: {
      bigger: '28px',
      big: '24px',
      base: '22px',
      small: '20px',
    },    
    weights: {
      bold: '700',
      extraLight: '200',
      light: '300',
      normal: '400',
    },
  },
  spacing: {
    smallGap: '17px',
    gap: '24px',
    indent: '3.5em',
    sectionTitleWidth: '388px',
  }
}

export default theme

import React from 'react'

import { StyledContainer } from './styles'

const Container = ({
  as,
  className,
  children,
  enableOddEven = false,
  enableMobileGutter = true,
  borderBottom = false,
  paddingBottom = false,
  teal = false,
}) => (
  <StyledContainer
    enableOddEven={enableOddEven}
    className={className}
    enableMobileGutter={enableMobileGutter}
    as={as}
    borderBottom={borderBottom}
    paddingBottom={paddingBottom}
    teal={teal}
  >
    {children}
  </StyledContainer>
)

export default Container

module.exports = {
  author: 'Zoopay', // Author for RSS author segment and SEO schema
  authorUrl: 'Zoopay', // URL used for author and publisher schema, can be a social profile or other personal site
  backgroundColor: '#ffffff', // Used for Offline Manifest
  copyright: 'Copyright © 2019 Zoopay', // Copyright string for the RSS feed
  gaEnabled: false,
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  publisher: 'Zoopay', // Organization name used for SEO schema
  redirectAfterEnquiryTimeout: 10000,
  serverUrl: 'https://www-stage-api.zoopay.com',
  serviceGTM: 'UA-5851359-8',
  serviceInfinity: '338433',
  serviceIntercom: 'zwjqmxxx',
  serviceMaxmind: '117034333',
  serviceOptimize: 'GTM-NNNJNXXX',
  serviceSentryDSN: 'xxx',
  shareImage: '/images/android-icon-512x512.png', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageHeight: 600, // Change to the height of your default share image
  shareImageWidth: 900, // Change to the width of your default share image
  shortTitle: 'Zoopay', // Used for App manifest e.g. Mobile Home Screen
  siteDescription:
    'Talk to Our Zoopay Team Today on +44 207 740 0001 or get a Free Demo. Save Money on Your Personal and Business Currency Payments.',
  siteLogo: '/images/android-icon-512x512.png', // Logo used for SEO, RSS, and App manifest
  siteTitle: 'Zoopay',
  siteTitleAlt: 'Zoopay', // This allows an alternative site title for SEO schema.
  siteUrl: 'https://www-stage.zoopay.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  swiftChecker: 'http://www.swift.com/bsl/index.faces',
  themeColor: '#143d53', // Used for Offline Manifest
  userTwitter: '@zoopayfx', // Change for Twitter Cards
  phoneNumber: 'tel:+442077400001',
}

import React from 'react'
import Container from '../Container'
import SectionInner from '../SectionInner'

const Section = ({ children, borderBottom = true, paddingBottom = true, lowLineHeight=false, teal = false, noSectionPadding = false }) => (
  <Container as="section" paddingBottom={paddingBottom} teal={teal} lowLineHeight={lowLineHeight} noSectionPadding={noSectionPadding}>
    <SectionInner borderBottom={borderBottom} teal={teal} lowLineHeight={lowLineHeight} noSectionPadding={noSectionPadding}>{children}</SectionInner>
  </Container>
)

export default Section

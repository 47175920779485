import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'

import favicon from '../../../static/images/favicon.ico'
import GlobalStyle from '../../styles/global'
import theme from '../../styles/theme'
import config from '../../config'
import Footer from '../Footer'
import CookiePolicy from '../CookiePolicy'
import Menu from '../Menu'
import SkipLink from '../SkipLink'

const SiteRoot = styled.div`
  position: relative;
`

const Main = styled.div`
  &.teal {
    background: ${props => props.theme.colors.tealBackground};
  }
`

const Template = ({ children, gateway = false, dark = false, teal = false }) => {
  return (
    <SiteRoot className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <>
          <SkipLink />
          <CookiePolicy />
          <Menu gateway={gateway} dark={dark} />
          <Main className={`siteContent main ${teal ? 'teal' : ''}`}>{children}</Main>
          <Footer gateway={gateway} dark={dark} />
        </>
      </ThemeProvider>
      <GlobalStyle />
    </SiteRoot>
  )
}

export default Template

module.exports = {
  gaEnabled: true,
  serverUrl: 'https://www-api.zoopay.com',
  serviceGTM: 'UA-5851359-8',
  serviceInfinity: '338433',
  serviceIntercom: 'z50d6b3n333',
  serviceMaxmind: '1170343333',
  serviceOptimize: 'GTM-NNNJNHHXXX',
  serviceSentryDSN: 'xx',
  siteUrl: 'https://www.zoopay.com',
}

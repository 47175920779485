import React from 'react'
import styled, { css } from 'styled-components'

export const StyledHeader = styled(({ isOpen, gateway, dark, ...props }) => <header {...props} />)`
    z-index: 10;
	position: relative;
	height: 100px;
	color: ${props => props.theme.colors.lightGrey};
	box-shadow: 0px 1px 6px rgba(0,0,0,.4);
	background-color: ${props => props.gateway
		? props.theme.colors.mainHeaderDeepBlue
		: props.theme.colors.mainHeaderNavyDark};
	
	.container {
		padding: 0px;
	}
	.header-container {
		height: 100%;
	}
	.navbar-toggler {
		border: none;
		outline: none;
		height: 27px;
		margin: 14px 0 0 14px;
		padding: 0;
		border-color: ${props => props.theme.colors.white};		
	}
	.header-wrapper {
		height: 100px;
		background-color: ${props => props.dark 
			? props.theme.colors.navyDeepBlue 
			: 'none'};
	}
	h1 {
		margin-top: 94px;
		max-width: 445px;
		font-size: 40px;
		font-weight: bold;
	}
	p {
		width: 600px;
		font-size: 24px;
		font-weight: 500;
	}
	a {
		color: ${props => props.theme.colors.white};
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.19px;
	}
	.header-logo {
		margin: 0px 50px 0 0;
		font-size: 30px;
		font-family: 'Comfortaa', cursive;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.36px;
		color: ${props => props.theme.colors.lightGrey};
		img {
			width: 212px;
  	}
	}
	.navbar{
		height: 100%;
	}
	.navbar-nav {
		width: 100%;
		margin: 6px 0 0 0;
		display: flex;
		justify-content: space-between;
		.nav-link {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			margin: 0 0px;
			padding: 0;
      		letter-spacing: 0.19px;
			transition:  .2s ease-in-out all;
			&:hover {
				transition:  .2s ease-in-out all;
				color: ${props => props.theme.colors.aquaBlue};
			}
		}
		.nav-item {
			flex-basis: auto;
			display: flex;
			align-items: center;
		}
		.container-left, .container-right {
			display: flex;
		}
		.container-left {
			.nav-link {
				margin: 0 10px;
			}
		}
		.container-right {
			.nav-item {
				padding: 0px;
				font-size:11px;
				.nav-link {
					height: 40px;
					flex: 1 1 0;
					white-space: nowrap;
					display: flex;
					align-items: center;
					padding: 0 12px;
					font-weight: bold;
					letter-spacing: 1.17px;
					border-radius: 2px;
				}				
				&.try-demo .nav-link {
					background-color: ${props => props.theme.colors.aquaBlue};
					color: ${props => props.theme.colors.blue};
					margin-left: 40px;
				}
				&.log-in .nav-link{
					background-color: ${props => props.gateway
						? props.theme.colors.mainHeaderDeepBlue
						: (props.dark ? props.theme.colors.navyDeepBlue : props.theme.colors.mainHeaderNavyDark)};
					border: 1px solid ${props => props.theme.colors.headerOpenGrey};
					color: white;
					padding: 0 10px;
				}
			}
		}
		.item {
			height: 40px;
			text-align: center;
			flex: 1 1 0;
			white-space: nowrap;
		}		
	}
	
	${props =>
    props.isOpen
      ? css`
          .header-container {
            background: ${props => props.theme.colors.headerOpenGrey};
            overflow: visible;
            color: ${props => props.theme.colors.blue};
            font-size: 16px;
            position: relative;
          }
          .navbar {
            align-items: flex-start;
          }
        `
      : css``
  }
  
  @media (max-width: ${props => props.theme.responsive.large}) {
    .navbar-nav {
      .container-right {
        .nav-item.try-demo .nav-link {
          margin-left: 15px;
        }
      }
    }
    .header-logo {
      margin: 0px 15px 0 0;
      img {
        width: 120px;
      }
    }  
	}


  @media screen and (max-width: ${props => props.theme.responsive.medium}) 
  and (min-width: ${props => props.theme.responsive.small}) {
    .header-logo {
      margin: 0px 10px 0 0;
      img {
        width: 120px;
      }
    }    
    .navbar-nav {
      .container-left .nav-link {
        display: none;
      }
      .container-right {
        .nav-item.log-in .nav-link {
          padding: 0 12px;
        }
        .nav-item.try-demo .nav-link {
          margin-left: 15px;
        }
      }
    }
	}

  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    height: 60px;
		position: relative;
		width: 100%;	
    .header-logo {
      img {
        margin: -5px 0 0 0;
      }
    }  
		.header-wrapper {
			width: 100%;
			height: inherit;
			box-shadow: ${props => props.dark
				? '0 10px 6px rgba(0,0,0,.5)'
				: 'none'};
			background-color: ${props => props.gateway
				? 'none'
				: 'rgba(0,0,0,0.28)'};
			.header-container {
				height: inherit;
			}
			.navbar {
				padding: 0px;
				.header-logo {
					font-size: 24px;
					text-align: center;
					flex: 1 1 0;
					margin: 13px 0px 0 0;
					align-self: flex-start;
				}
				.navbar-nav {
					.container-right {
						display: none;
						margin: 0 18px 23px;
						.nav-link {
							text-align: center;
							justify-content: center;
							border-radius: 3px;
						}
						.nav-item.log-in .nav-link {
							padding: 0 12px;
						}
						.nav-item.try-demo .nav-link {
							margin-left: 18px;
						}
					}
					.container-left {
						margin: 0px 0 0 10px;
						height: 0;
						overflow: hidden;
						flex-direction: column;
						.nav-item {
							margin: 18px 0 0 0;
							.nav-link {
								text-transform: initial;
							}
						}
					}
				}
			}
			.navbar-collapse{
				height: inherit;
				.navbar-nav {
					height: 100vh;
				}
			}
		}
		.navbar-toggler {
			position: absolute;
			top: 0px;
	  }
	  
	  ${props => props.isOpen
			? css`
					height: 100vh;
					position: fixed;
					width: 100%;
					background-color: ${props => props.theme.responsive.headerOpenGrey};
					a {
						color: ${props => props.gateway
							? props.theme.colors.blue
							: props.theme.colors.mainHeaderNavyDark};
					}
					.header-wrapper {
						height: inherit;
						.header-container {
							background: ${props => props.theme.responsive.headerOpenGrey};
							height: inherit;
							.navbar {
								padding: 0px;
								.animated-icon span {
									background: ${props => props.gateway
										? props.theme.colors.blue
										: props.theme.colors.mainHeaderNavyDark};
								}
								.header-logo {
									display: none;
								}
								.navbar-nav {
									.container-left, .container-right {
										display: flex;
									}
									.container-left {
										margin: 50px 0 0 10px;
										height: 100vh;
										overflow: visible;
										.nav-item {
											margin: 18px 0 0 0;
											.nav-link {
												text-transform: initial;
											}
										}
									}
								}
							}
							.navbar-collapse {
								height: inherit;
								.navbar-nav {
									height: 100vh;
								}
							}
						}
					}
				`
			: css``
		}
  }
`


export const AnimatedButton = styled(({ isOpen, gateway, ...props }) => <button {...props} />)`
  .animated-icon {
			width: 27px;
			height: 18px;
			position: relative;
			margin: 0px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .5s ease-in-out;
			-moz-transition: .5s ease-in-out;
			-o-transition: .5s ease-in-out;
			transition: .5s ease-in-out;
			cursor: pointer;
	}
	
	.animated-icon span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			border-radius: 0px;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
	}
	
	.animated-icon span {
			background: #f3e5f5;
	}
	
	
	.animated-icon span:nth-child(1) {
			${props => props.isOpen
				? css`
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
						transform-origin: left center;
						top: 0px;
						left: 8px;
					`
				: css`
						top: 0px;
						-webkit-transform-origin: left center;
						-moz-transform-origin: left center;
						-o-transform-origin: left center;
						transform-origin: left center;
					`
			}
			
	}
	
	.animated-icon span:nth-child(2) {
			${props => props.isOpen
				? css`
						width: 0%;
						opacity: 0;
					`
				: css`
						top: 10px;
						-webkit-transform-origin: left center;
						-moz-transform-origin: left center;
						-o-transform-origin: left center;
						transform-origin: left center;
					`
			}
			
	}
	
	.animated-icon span:nth-child(3) {
			${props => props.isOpen
				? css`
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
						transform-origin: left center;
						top: 20px;
						left: 8px;
					`
				: css`
						top: 20px;
						-webkit-transform-origin: left center;
						-moz-transform-origin: left center;
						-o-transform-origin: left center;
						transform-origin: left center;
					`
			}			
	}
`
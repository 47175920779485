import React from 'react'
import { Link } from 'gatsby'
import { StyledFooter } from './styles'

import social1 from './../../images/social1.png'
import social2 from './../../images/social2.png'
import social3 from './../../images/social3.png'
import social4 from './../../images/social4.png'
import social5 from './../../images/social5.png'
import social1Gw from './../../images/social1_gw.svg'
import social2Gw from './../../images/social2_gw.svg'
import social3Gw from './../../images/social3_gw.svg'
import social4Gw from './../../images/social4_gw.svg'
import social5Gw from './../../images/social5_gw.svg'
import zoopayLogoFooter from '../../images/zoopay_footer_logo.png'

const Footer = ({gateway, dark}) => (
  <StyledFooter gateway={gateway} dark={dark}>
    <div className="container">
      {/*<div className="text-centered social-icons">*/}
      {/*  <a href="#" className="social-circle"><img src={gateway ? social1Gw : social1} alt="Facebook" /></a>*/}
      {/*  <a href="#" className="social-circle"><img src={gateway ? social2Gw : social2} alt="Twitter" /></a>*/}
      {/*  <a href="#" className="social-circle"><img src={gateway ? social3Gw : social3} alt="LinkedIn" /></a>*/}
      {/*  <a href="#" className="social-circle"><img src={gateway ? social4Gw : social4} alt="YouTube" /></a>*/}
      {/*  <a href="#" className="social-circle"><img src={gateway ? social5Gw : social5} alt="Google" /></a>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-lg-3 col-12 left-col">
          <Link className="zoopay-logo" to="/">
            <img src={zoopayLogoFooter} alt="Zoopay" />
          </Link>
          <p>
            Zoopay <br />
            Unit 35 <br />
            Hobbs Court, <br />
            2 Jacob Street, <br />
            London <br />
            SE1 2BG <br />
            +44 207 740 0001 <br />
          </p>
        </div>
        <div className="col-lg-3 col-6 links-column">
          <ul>
            <li className="li-header">Payments</li>
            <li><Link to="/currencies-we-support">Supported Currencies</Link></li>
            <li><a href="https://arch-api-docs.zoopay.com">API Documentation</a></li>
            <li><a href="https://arch-login.zoopay.com/#/login">Arch Login</a></li>

          </ul>
        </div>
        <div className="col-lg-3 col-6 links-column">
          <ul>
            <li className="li-header">Company</li>
            <li><Link to="/about">About us</Link></li>
            <li><a href="https://www.linkedin.com/company/zoopay-limited/">Careers</a></li>
            <li><Link to="/offices">Offices</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>

          </ul>
        </div>
        <div className="col-lg-3 col-6 links-column">
          <ul>
            <li className="li-header">Legal</li>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/cookies-policy">Cookie Policy</Link></li>

          </ul>
        </div>
        <div className="col-lg-12 footer-info">
          Zoopay Limited (Company Number: 08764359) services are provided by Currency Solutions Limited (Company Number
          04864491).
          Currency Solutions Ltd is authorised and regulated by the Financial Conduct Authority (FCA) as a Payment
          Institution, (FRN 512130) and as an Investment Firm, (FRN 602082). Company is registered in England (No.
          04864491).
          Currency Solutions is also registered with the Information Commissioner’s Office (ICO).
        </div>
        <div className="col-lg-12 footer-copyrights">© ZOOPAY 2019. All rights reserved</div>
      </div>
    </div>
  </StyledFooter>
)

export default Footer

import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import theme from './theme'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  @import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap');
  @font-face {
    font-family: 'Syne-Bold';
    src: url('/fonts/Syne-Bold/Syne-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Syne-Bold/Syne-Bold.otf')  format('opentype'),
        url('/fonts/Syne-Bold/Syne-Bold.woff') format('woff'),
        url('/fonts/Syne-Bold/Syne-Bold.ttf')  format('truetype'),
        url('/fonts/Syne-Bold/Syne-Bold.svg#Syne-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :focus {
    outline: 0;
  }
  
  body#wrpr {
    color: ${theme.colors.navy};
    background-color: ${theme.colors.white};
    font-family: ${theme.type.fonts.base};
    font-size: ${theme.type.sizes.base};
    font-weight: ${theme.type.weights.normal};
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    &.notMobile .hero-container img.hero-image {
      display: block;
    }
    &.mobile .hero-container img.hero-image-mobile {
      display: block;
    }
  }

  /*
   * Typography
   */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.type.fonts.base};
  }

  h1 {

  }

  h2 {
    font-weight: ${theme.type.weights.bold};
  }

  h3, h4 {
    span {
      color: ${theme.colors.aquaBlue};
    }
  }

  h4 {
    @media (max-width: ${theme.responsive.large}) {
      font-size: ${theme.type.sizes.hardSizes.basePlus};
    }
  }

  p {
    line-height: 1.6;
    color: ${theme.colors.navy};
    margin: 0 0 2em 0;
    font-size: 1.1rem;
  }
  
  a {
    color: ${theme.colors.aquaBlue};
    transition: all 200ms ease-in-out;
  }
  
  a:hover {
    color: ${theme.colors.aquaBtnSelected};
    transition: all 200ms ease-in-out;
  }

  /*
   * Buttons / Input
   */
  button,
  textarea,
  input[type="text"],
  input[type="radio"],
  input[type="number"],
  input[type="password"] {
    appearance: none;
    background: red;
    border-radius: 0;
    border: none;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  /* Fix Footer to bottom of viewport */
  html, body {
    box-sizing: border-box;
    height: 100%;
  }

  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }


  /* General buttons styling */
  .btn-primary, .btn-primary:visited {
    border-color: ${theme.colors.aquaBlue};
    background-color: ${theme.colors.aquaBlue};
    border-radius: 3px;
    color: $blue !important;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.17px;
    text-transform: uppercase;
  }
  
  .btn.btn-primary {
    cursor: pointer;
    &.aqua, &.aqua:visited {
      color: ${theme.colors.deepButtonBlue} !important;
      border-color: ${theme.colors.aquaBlue};
      background-color: ${theme.colors.aquaBlue} !important;
    }
    &.aqua:hover {
      background-color: ${theme.colors.aquaBtnHover} !important;
      border-color: ${theme.colors.aquaBtnHover};
    }
    &.aqua:active, &.aqua:focus {
      background-color: ${theme.colors.aquaBtnSelected} !important;
      border-color: ${theme.colors.aquaBtnSelected};
      outline: none;
      box-shadow: none;
    }
  }

  .btn.btn-primary {
    cursor: pointer;
    height: 40px;
    line-height: 28px;
  }
  
  .btn-primary:hover {
    background-color: #0069d9 !important;
    border-color: #0062cc;
    color: white !important;
  }
  
  .btn-secondary, .btn-secondary:visited {
    background-color: ${theme.colors.mainHeaderNavyDark};
    color: white;
    border-color: white;
  }

  .btn-secondary:hover {
    background-color: ${theme.colors.navyBtnHover};
    background-color: ${theme.colors.aquaBtnHover} !important;
    border-color: ${theme.colors.aquaBtnHover} !important;
    color: ${theme.colors.deepButtonBlue} !important;
  }
  .btn-secondary:active, .btn-secondary:focus {
    border-color: ${theme.colors.navyBtnSelected};
    outline: none;
    box-shadow: none;
  }

  .center-align {
    display: flex;
    justify-content: center;
  }
  

  /* Other global styling */

  .section-title {
    p {
      font-size: 21px;
      margin-top: 11px;
    }
  }
  .text-centered {
    text-align: center;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .main {
    .section-title h2 {
      font-size: 40px;
      font-weight: bold;
      span {
        color: ${theme.colors.aquaBlue};
      }
    }
  }

  .section-title {
    p {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  /* Footer */

  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  .ReactModal__Body--open, .menu-open {
    height: 100%;
    overflow: hidden;
  }

  .menu-open .cookie-policy {
    display: none;
  }

  _:-ms-lang(x), header { 
    display: table; 
    width: 100%
  }

  _:-ms-lang(x), footer { 
    display: table;
  }

  /* Global RWD */

  @media (max-width: ${theme.responsive.small}) {
    .section-title {
      p {
        font-size: 16px;
        margin-top: 36px;
      }
    }
    .main h2 {
      font-size: 24px;
    }
  }
`
export default GlobalStyle

// To stop over services outwriting our cookies
const uniqueCookieIdentifier = '_currencysolutions-js-'

const getName = name => {
  return `${uniqueCookieIdentifier}${name}`
}

const deleteCookie = name => {
  document.cookie = `${getName(name)}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${getName(name)}=`)

  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift()
  }
}

const setCookie = (name, value, durationInDays) => {
  let d = new Date()
  d.setTime(d.getTime() + durationInDays * 24 * 60 * 60 * 1000)
  let expires = `expires= ${d.toUTCString()}`
  document.cookie = `${getName(name)}=${value};${expires};path=/`
}

export { deleteCookie, getCookie, setCookie }
